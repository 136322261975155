/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, SeparateLine, Menu, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, Image, Fullmap, FullmapWrap, FullmapCover, SeparateLineWrap, Subtitle, ContactForm, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <Column name={"uvod"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(0,0,0,1)"}}>
        </Column>


        <Column name={"uvod"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(0,0,0,1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--hvr5 js-anim  --anim3" anim={"3"}>
              
              <Title className="title-box title-box--left fs--20" content={"<span style=\"color: rgb(255, 255, 255);\"><a href=\"/\">Tribečské múzeum v Topoľčanoch</a></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"uvod"} style={{"backgroundColor":"rgba(245,166,35,1)"}}>
        </SeparateLine>


        <Column className="--menu css-nwo4mr" menu={true} name={"menu"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(217, 217, 219, 1)"}} parallax={false} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/7746/a30f33d4a6134c16a4d4655db94abc5c_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/7746/a30f33d4a6134c16a4d4655db94abc5c_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/7746/a30f33d4a6134c16a4d4655db94abc5c_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/7746/a30f33d4a6134c16a4d4655db94abc5c_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/7746/a30f33d4a6134c16a4d4655db94abc5c_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/7746/a30f33d4a6134c16a4d4655db94abc5c_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/7746/a30f33d4a6134c16a4d4655db94abc5c_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/7746/a30f33d4a6134c16a4d4655db94abc5c_s=3000x_.jpg);
    }
  
    `}>
          
          <Menu className="--full flex--center" style={{"paddingTop":0,"paddingBottom":0}} fullscreen={true}>
            
            <ColumnWrapper className="menu-logo-box --hvr5 js-anim  --anim5" anim={"5"}>
              
              <Title className="title-box ff--3 fs--48" content={"<div></div><span style=\"font-weight: bold;\"><a href=\"#odber_noviniek\"><br><br><span style=\"color: rgb(255, 255, 255);\">Kontakt</span></a></span>"}>
              </Title>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox className="--right" style={{"marginTop":0,"paddingTop":0,"marginBottom":79.53125,"paddingBottom":56.609375}}>
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 btn-box--center ff--3 fs--13 w--900 btn-box--invert ls--02 lh--1" href={"/"} target={""} content={"Hlavná stránka"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 btn-box--center ff--3 fs--13 w--900 ls--02 lh--1" href={"/sluzby"} target={""} content={"Služby"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 btn-box--center ff--3 fs--13 w--900 ls--02 lh--1" href={"/kniznica"} target={""} content={"Knižnica"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 btn-box--center ff--3 fs--13 w--900 ls--02 lh--1" href={"/projekty"} target={""} content={"Projekty"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 btn-box--center ff--3 fs--13 w--900 ls--02 lh--1" href={"/podujatia"} target={""} content={"Podujatia"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 btn-box--center ff--3 fs--13 w--900 ls--02 lh--1" href={"/#vystavy"} target={""} content={"Výstavy"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 btn-box--center ff--3 fs--13 w--900 ls--02 lh--1" href={"/muzeum-online#vystavy"} target={""} content={"Múzeum online"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

          <Menu className="--full flex--center" style={{"maxWidth":520,"marginTop":0,"marginBottom":0,"paddingBottom":0}} fullscreen={true}>
            
            <ColumnWrapper className="menu-logo-box --hvr5">
            </ColumnWrapper>

            <ColumnWrapper className="menu-logo-box --hvr5">
            </ColumnWrapper>

            <ColumnWrapper className="menu-logo-box --hvr5 js-anim  --anim6" anim={"6"} style={{"paddingTop":0}}>
              
              <Image src={"https://cdn.swbpg.com/t/7746/84a41b1070c64df3bf6e5f948a183a95_s=660x_.png"} svg={false} href={"/"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/7746/84a41b1070c64df3bf6e5f948a183a95_s=350x_.png 350w, https://cdn.swbpg.com/t/7746/84a41b1070c64df3bf6e5f948a183a95_s=660x_.png 660w, https://cdn.swbpg.com/t/7746/84a41b1070c64df3bf6e5f948a183a95_s=860x_.png 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

            </MenuWrap>

          </Menu>

        </Column>


        <Column name={"adresa"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(155, 155, 155, 1)"}}>
          
          <ColumnWrap className="column__flex el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--hvr2 --shape5 --style2 js-anim  --anim3 --shadow2 --center" anim={"3"} style={{"paddingTop":13,"paddingBottom":17.4375,"backgroundColor":"rgba(147,56,124,1)"}}>
              
              <Title className="title-box ff--3 fs--30 title-box--invert" content={"Krušovská 291/1, 955 01 Topoľčany"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" name={"ahbo8zvbxm"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(155, 155, 155, 1)"}}>
          
          <FullmapWrap className="--full">
            
            <FullmapCover zoom={"15"} place={"Krušovská 291/1, Topoľčany"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Column name={"cyh991smldo"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(217, 217, 219, 1)"}} layout={"l33"}>
          
          <ColumnWrap className="--full column__flex el--3 flex--stretch" columns={"3"}>
          </ColumnWrap>

        </Column>


        <Column name={"msmlz91yok9"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(155, 155, 155, 1)"}}>
          
          <ColumnWrap className="column__flex --left el--2 --full" style={{"paddingTop":31,"paddingBottom":0}} columns={"2"} fullscreen={true}>
            
            <ColumnWrapper className="js-anim  --anim3 --center" anim={"3"} style={{"maxWidth":588,"paddingTop":0,"paddingBottom":0}}>
              
              <Title className="title-box ff--3 fs--24" content={"Trasa z autobusovej a vlakovej stanice"}>
              </Title>

              <Image className="--center" src={"https://cdn.swbpg.com/o/g/Ikonky/obecne/5295b4c8c0d74a51b522ffb0650bcc1f.svg"} svg={false} sizes="(max-width: 639px) 100vw, 50vw" style={{"maxWidth":66}} srcSet={""}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"msmlz91yok9"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(155, 155, 155, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--2 --full" columns={"2"} fullscreen={true}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s8" anim={"5"} animS={"8"} style={{"maxWidth":480}}>
              
              <Image className="--left" src={"https://cdn.swbpg.com/t/7746/fde10a3e75084529a3aa673b7c0dd091_s=350x_.png"} svg={false} href={"https://www.google.sk/maps/dir/Autobusov%C3%A1+stanica,+J.+Holl%C3%A9ho,+Topo%C4%BE%C4%8Dany/Tribe%C4%8Dsk%C3%A9+m%C3%BAzeum+v+Topo%C4%BE%C4%8Danoch,+1,+Kru%C5%A1ovsk%C3%A1,+Topo%C4%BE%C4%8Dany/@48.5594661,18.170857,17z/data=!4m24!4m23!1m15!1m1!1s0x476b4b17f4925f51:0x835811db85f38253!2m2!1d18.1706975!2d48.5620024!3m4!1m2!1d18.1746344!2d48.5590076!3s0x476b4aaff4de2637:0x9e9653d30930ff4!3m4!1m2!1d18.174818!2d48.5577279!3s0x476b4aa573f30ca7:0x272d9b4da490a5af!1m5!1m1!1s0x476b4aa5a196eb53:0x45d6c43a1fcc0a7c!2m2!1d18.1753863!2d48.5573379!3e2?hl=sk"} sizes="(max-width: 639px) 100vw, 50vw" style={{"maxWidth":60}} srcSet={"https://cdn.swbpg.com/t/7746/fde10a3e75084529a3aa673b7c0dd091_s=350x_.png 350w"} target={"_blank"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"msmlz91yok9"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(155, 155, 155, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--2 --full" columns={"2"} fullscreen={true}>
            
            <ColumnWrapper className="js-anim  --anim4 --anim-s8" anim={"4"} animS={"8"} style={{"maxWidth":480}}>
              
              <Image className="--left" src={"https://cdn.swbpg.com/t/7746/2c4879ed47ed44e08bf9e1cad09b1619_s=350x_.png"} svg={false} href={"https://www.google.sk/maps/dir/48.5623727,18.1700538/Tribe%C4%8Dsk%C3%A9+m%C3%BAzeum+v+Topo%C4%BE%C4%8Danoch,+1,+Kru%C5%A1ovsk%C3%A1,+Topo%C4%BE%C4%8Dany/@48.5595983,18.1709991,17z/data=!4m24!4m23!1m15!3m4!1m2!1d18.1705084!2d48.5618813!3s0x476b4ab0ff474e77:0x894f2baf71687dbe!3m4!1m2!1d18.1746344!2d48.5590076!3s0x476b4aaff4de2637:0x9e9653d30930ff4!3m4!1m2!1d18.1750334!2d48.5581185!3s0x476b4aa57a17d3cb:0x4a668848bb892d60!1m5!1m1!1s0x476b4aa5a196eb53:0x45d6c43a1fcc0a7c!2m2!1d18.1753863!2d48.5573379!3e2?hl=sk"} sizes="(max-width: 639px) 100vw, 50vw" style={{"maxWidth":60}} srcSet={"https://cdn.swbpg.com/t/7746/2c4879ed47ed44e08bf9e1cad09b1619_s=350x_.png 350w"} target={"_blank"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"72ezpg1r3x4"} style={{"paddingTop":10,"paddingBottom":21.4453125,"backgroundColor":"rgba(155, 155, 155, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --hvr2 --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column name={"y0wh77t31s9"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(155, 155, 155, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --hvr5 --center el--1" style={{"paddingTop":0}} columns={"1"}>
            
            <ColumnWrapper className="--hvr5" style={{"paddingTop":0,"paddingBottom":0}}>
              
              <Image src={"https://cdn.swbpg.com/t/7746/e9f0768b042e4a45a060b95f85713f92_s=660x_.png"} svg={false} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":250,"paddingBottom":0}} srcSet={"https://cdn.swbpg.com/t/7746/e9f0768b042e4a45a060b95f85713f92_s=350x_.png 350w, https://cdn.swbpg.com/t/7746/e9f0768b042e4a45a060b95f85713f92_s=660x_.png 660w, https://cdn.swbpg.com/t/7746/e9f0768b042e4a45a060b95f85713f92_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"3iivmn2tth6"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(155, 155, 155, 1)"}}>
        </SeparateLine>


        <SeparateLine name={"0a22qrjl396l"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(155, 155, 155, 1)"}}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"g8yrspz4hfw"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(155, 155, 155, 1)"}} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--hvr5 js-anim  --anim5" anim={"5"}>
              
              <Title className="title-box title-box--left ff--3 fs--30 w--600" content={"<span style=\"color: rgb(255, 255, 255);\">Kontakty na zamestnancov</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"k4kl951dc6"} style={{"paddingTop":56,"paddingBottom":4.78125,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--hvr2 js-anim  --anim5" anim={"5"}>
              
              <Title className="title-box title-box--left ff--3 fs--22" content={"<span style=\"color: rgb(245, 166, 35);\">❖</span>&nbsp;<span style=\"color: rgb(245, 166, 35); font-weight: bold;\">Úsek riaditeľa&nbsp;</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"k4kl951dc6"} style={{"paddingTop":4,"paddingBottom":4.78125,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--hvr2">
              
              <Title className="title-box title-box--center ff--3 fs--20" content={"<span style=\"color: rgb(155, 155, 155);\">Mgr. Ľubomír Opáth<br></span><span style=\"color: rgb(255, 255, 255);\">riaditeľ</span><span style=\"color: rgb(155, 155, 155);\"><br></span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim3" anim={"3"}>
              
              <Title className="title-box ff--3 fs--26" style={{"paddingTop":5}} content={"<span style=\"color: rgb(245, 166, 35);\">✆&nbsp;<a href=\"tel:038/5323 021\">038/5323 021</a></span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper style={{"paddingTop":12}}>
              
              <Title className="title-box title-box--center ff--3 fs--20" style={{"marginTop":6.5703125}} content={"<span style=\"color: rgb(155, 155, 155);\"><a href=\"mailto:office@tribecskemuzeum.sk\" target=\"_blank\">office@tribecskemuzeum.sk</a></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"jf6wb5pr6wo"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <SeparateLineWrap className="--invert" style={{"paddingTop":0,"paddingBottom":0}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"k4kl951dc6"} style={{"paddingTop":4,"paddingBottom":4.78125,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--hvr2">
              
              <Title className="title-box title-box--center ff--3 fs--20" content={"<div><span style=\"color: rgb(155, 155, 155);\">Miroslava Režová&nbsp;</span></div><div><span style=\"color: rgb(240, 240, 242);\">&nbsp; &nbsp; asistentka riaditeľa&nbsp;</span><br></div>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim3" anim={"3"}>
              
              <Title className="title-box ff--3 fs--26" style={{"paddingTop":5}} content={"<span style=\"color: rgb(245, 166, 35);\">✆</span>&nbsp;<span style=\"color: rgb(245, 166, 35);\"><a href=\"tel:038/5323 253\">038/5323 253</a></span><br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper style={{"paddingTop":11}}>
              
              <Title className="title-box title-box--center ff--3 fs--20" style={{"marginTop":4.1640625}} content={"<a href=\"mailto:sekretariat@tribecskemuzeum.sk\" target=\"_blank\" style=\"color: rgb(155, 155, 155);\">sekretariat@tribecskemuzeum.sk</a>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"k4kl951dc6"} style={{"paddingTop":41,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--hvr2 js-anim  --anim5" anim={"5"}>
              
              <Title className="title-box title-box--left ff--3 fs--22" content={"<span style=\"color: rgb(245, 166, 35);\">❖</span>&nbsp;<span style=\"color: rgb(245, 166, 35); font-weight: bold;\">Odborný úsek</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box ff--3 fs--26" style={{"paddingTop":5}} content={"<br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--left ff--3 fs--20" style={{"paddingTop":9}} content={"<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"k4kl951dc6"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"paddingBottom":0}} columns={"3"}>
            
            <ColumnWrapper className="--hvr2">
              
              <Title className="title-box title-box--left ff--3 fs--20" style={{"marginTop":13.5234375}} content={"<div><span style=\"color: rgb(155, 155, 155);\">&nbsp; &nbsp; &nbsp; Mgr. Stanislava Saadoonová</span></div><div><span style=\"color: rgb(240, 240, 242);\">&nbsp; &nbsp; &nbsp; lektorka, kultúrna manažérka</span><br></div>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper style={{"paddingTop":23}}>
              
              <Title className="title-box title-box--center ff--3 fs--20" style={{"marginTop":4.515625}} content={"<a href=\"mailto:lektorka@tribecskemuzeum.sk\" target=\"_blank\" style=\"color: rgb(155, 155, 155);\">lektorka@tribecskemuzeum.sk</a>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4" anim={"4"} style={{"paddingTop":0,"paddingBottom":0}}>
              
              <Title className="title-box title-box--left ff--3 fs--20" style={{"paddingTop":0,"paddingBottom":0}} content={"<span style=\"color: rgb(245, 166, 35);\">Nahlásenie termínov podujatí&nbsp;<br></span><span style=\"color: rgb(255, 255, 255);\">Mgr. Stanislava Saadoonová</span><br><div><span style=\"color: rgb(245, 166, 35); background-color: transparent; letter-spacing: 0.05em;\">tel. č. </span><a href=\"tell:038/5323 253\" style=\"background-color: transparent; letter-spacing: 0.05em; color: rgb(245, 166, 35);\">038/5323 253</a><br></div>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"jf6wb5pr6wo"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <SeparateLineWrap className="--invert" style={{"paddingTop":0,"paddingBottom":0}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"k4kl951dc6"} style={{"paddingTop":0,"paddingBottom":4.78125,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--hvr2">
              
              <Title className="title-box title-box--left ff--3 fs--20" content={"<div><span style=\"color: rgb(155, 155, 155);\">&nbsp; &nbsp; &nbsp; Mgr. Peter Gábor, PhD.&nbsp;</span></div><div>&nbsp; &nbsp; &nbsp;<span style=\"color: rgb(240, 240, 242);\"> archeológ</span><br></div>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper style={{"paddingTop":13}}>
              
              <Title className="title-box title-box--center ff--3 fs--20" style={{"marginTop":3.296875}} content={"<span style=\"color: rgb(155, 155, 155);\"><a href=\"mailto:archeolog@tribecskemuzeum.sk\" target=\"_blank\">archeolog@tribecskemuzeum.sk</a></span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"jf6wb5pr6wo"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <SeparateLineWrap className="--invert" style={{"paddingTop":0,"paddingBottom":0}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"k4kl951dc6"} style={{"paddingTop":0,"paddingBottom":4.78125,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--hvr2">
              
              <Title className="title-box title-box--left ff--3 fs--20" content={"<div><span style=\"color: rgb(155, 155, 155);\">&nbsp; &nbsp; &nbsp; Mgr. Bohuš Sasko&nbsp;</span></div><div><span style=\"color: rgb(240, 240, 242);\">&nbsp; &nbsp; &nbsp; historik</span><br></div>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper style={{"paddingTop":14}}>
              
              <Title className="title-box title-box--center ff--3 fs--20" style={{"marginTop":5.5078125}} content={"<a href=\"mailto:historik@tribecskemuzeum.sk\" target=\"_blank\" style=\"color: rgb(155, 155, 155);\">historik@tribecskemuzeum.sk</a>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"jf6wb5pr6wo"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <SeparateLineWrap className="--invert" style={{"paddingTop":0,"paddingBottom":0}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"k4kl951dc6"} style={{"paddingTop":0,"paddingBottom":4.78125,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--hvr2">
              
              <Title className="title-box title-box--left ff--3 fs--20" content={"<div><span style=\"color: rgb(155, 155, 155);\">&nbsp; &nbsp; &nbsp; Mgr. Ondrej Godál&nbsp;</span></div><div><span style=\"color: rgb(240, 240, 242);\">&nbsp; &nbsp; &nbsp; historik</span><br></div>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper style={{"paddingTop":14}}>
              
              <Title className="title-box ff--3 fs--20" style={{"marginTop":3.875}} content={"<span style=\"color: rgb(155, 155, 155);\"><a href=\"mailto:historia@tribecskemuzeum.sk\" target=\"_blank\">historia@tribecskemuzeum.sk</a></span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"jf6wb5pr6wo"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <SeparateLineWrap className="--invert" style={{"paddingTop":0,"paddingBottom":0}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"k4kl951dc6"} style={{"paddingTop":0,"paddingBottom":4.78125,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--hvr2">
              
              <Title className="title-box title-box--left ff--3 fs--20" content={"&nbsp; &nbsp; &nbsp;<span style=\"color: rgb(155, 155, 155);\"> Ing. Oľga Kostolná</span><br><span style=\"color: rgb(255, 255, 255);\">&nbsp; &nbsp; &nbsp; botanička</span><br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper style={{"paddingTop":14}}>
              
              <Title className="title-box title-box--center ff--3 fs--20" style={{"marginTop":2.3515625}} content={"<a href=\"mailto:botanicka@tribecskemuzeum.sk\" target=\"_blank\" style=\"color: rgb(155, 155, 155);\">botanicka@tribecskemuzeum.sk</a>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"jf6wb5pr6wo"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <SeparateLineWrap className="--invert" style={{"paddingTop":0,"paddingBottom":0}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"k4kl951dc6"} style={{"paddingTop":0,"paddingBottom":4.78125,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--hvr2">
              
              <Title className="title-box title-box--left ff--3 fs--20" content={"<div><span style=\"color: rgb(155, 155, 155);\">&nbsp; &nbsp; &nbsp; Mgr. Jana Prícka&nbsp;</span></div><div><span style=\"color: rgb(240, 240, 242);\">&nbsp; &nbsp; &nbsp; geologička</span><br></div>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper style={{"paddingTop":14}}>
              
              <Title className="title-box title-box--center ff--3 fs--20" content={"<a href=\"mailto:geologicka@tribecskemuzeum.sk\" target=\"_blank\" style=\"color: rgb(155, 155, 155);\">geologicka@tribecskemuzeum.sk</a>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"jf6wb5pr6wo"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <SeparateLineWrap className="--invert" style={{"paddingTop":0,"paddingBottom":0}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"k4kl951dc6"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"paddingTop":0,"paddingBottom":0}} columns={"3"}>
            
            <ColumnWrapper className="--hvr2" style={{"marginTop":0,"paddingTop":0,"marginBottom":0,"paddingBottom":0}}>
              
              <Title className="title-box title-box--left ff--3 fs--20" style={{"marginTop":12.77435302734375,"paddingTop":0,"paddingBottom":0}} content={"<div><span style=\"color: rgb(155, 155, 155);\">&nbsp; &nbsp; &nbsp; Ing. Lenka Krošláková&nbsp;</span></div><div><span style=\"color: rgb(240, 240, 242);\">&nbsp; &nbsp; &nbsp; zoologička, zástupkyňa riaditeľa&nbsp; &nbsp; &nbsp;&nbsp;</span><br></div>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper style={{"marginTop":0,"paddingTop":10,"marginBottom":0}}>
              
              <Title className="title-box title-box--center ff--3 fs--20" style={{"marginTop":10}} content={"<span style=\"color: rgb(155, 155, 155);\"><a href=\"mailto:zoologicka@tribecskemuzeum.sk?subject=Etnologicka\">zoologicka@tribecskemuzeum.sk</a></span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"y3plkeadv5"} style={{"marginTop":0,"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <SeparateLineWrap className="--invert" style={{"paddingBottom":11}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"k4kl951dc6"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"maxWidth":1448,"paddingTop":0,"paddingLeft":74,"paddingRight":0,"paddingBottom":0}} columns={"3"}>
            
            <ColumnWrapper className="--hvr2 --left" style={{"maxWidth":1002,"marginTop":0,"paddingTop":0,"marginBottom":0,"paddingBottom":0}}>
              
              <Title className="title-box title-box--left ff--3 fs--20" style={{"maxWidth":456,"marginTop":5,"paddingTop":0,"paddingLeft":0,"paddingRight":0,"paddingBottom":10}} content={"<div><span style=\"color: rgb(155, 155, 155);\">Mgr. Barbora Kováčiková Bartová, PhD.&nbsp;</span></div><div><span style=\"color: rgb(240, 240, 242);\">etnologička&nbsp; &nbsp; &nbsp;&nbsp;</span><br></div>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":576,"marginTop":0,"paddingTop":10,"paddingLeft":0,"marginBottom":0}}>
              
              <Title className="title-box title-box--left ff--3 fs--20" style={{"marginTop":0,"paddingLeft":0}} content={"<span style=\"color: rgb(155, 155, 155);\"><a href=\"mailto:etnologia@tribecskemuzeum.sk\">etnologia@tribecskemuzeum.sk</a></span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"jf6wb5pr6wo"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <SeparateLineWrap className="--invert" style={{"paddingTop":0,"paddingBottom":0}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"k4kl951dc6"} style={{"paddingTop":0,"paddingBottom":4.78125,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--hvr2">
              
              <Title className="title-box title-box--left ff--3 fs--20" content={"<div><span style=\"color: rgb(155, 155, 155);\">&nbsp; &nbsp; &nbsp; Mária Bagová&nbsp;</span></div><div><span style=\"color: rgb(240, 240, 242);\">&nbsp; &nbsp; &nbsp; dokumentátorka, knižnica</span><br></div>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper style={{"paddingTop":13}}>
              
              <Title className="title-box title-box--center ff--3 fs--20" style={{"marginTop":5.015625}} content={"<span style=\"color: rgb(155, 155, 155);\"><a href=\"mailto:kniznica@tribecskemuzeum.sk\" target=\"_blank\">kniznica@tribecskemuzeum.sk</a></span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"jf6wb5pr6wo"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <SeparateLineWrap className="--invert" style={{"paddingTop":0,"paddingBottom":0}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"k4kl951dc6"} style={{"paddingTop":0,"paddingBottom":4.78125,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--hvr2">
              
              <Title className="title-box title-box--left ff--3 fs--20" content={"<div><span style=\"color: rgb(155, 155, 155);\">&nbsp; &nbsp; &nbsp; Mgr. art. Patrik Kucha&nbsp;</span></div><div><span style=\"color: rgb(240, 240, 242);\">&nbsp; &nbsp; &nbsp; výtvarník, fotograf&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><br></div>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper style={{"paddingTop":13}}>
              
              <Title className="title-box title-box--center ff--3 fs--20" style={{"marginTop":1.625}} content={"<a href=\"mailto:vytvarnik@tribecskemuzeum.sk\" target=\"_blank\" style=\"color: rgb(155, 155, 155);\">vytvarnik@tribecskemuzeum.sk</a>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"jf6wb5pr6wo"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <SeparateLineWrap className="--invert" style={{"paddingTop":0,"paddingBottom":0}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"k4kl951dc6"} style={{"paddingTop":0,"paddingBottom":4.78125,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--hvr2">
              
              <Title className="title-box title-box--left ff--3 fs--20" content={"<div><span style=\"color: rgb(155, 155, 155);\">&nbsp; &nbsp; &nbsp; Žofia Ľuptáková&nbsp;</span></div><div><span style=\"color: rgb(240, 240, 242);\">&nbsp; &nbsp; &nbsp; konzervátorka</span><br></div>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"k4kl951dc6"} style={{"paddingTop":41,"paddingBottom":4.78125,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--hvr2 js-anim  --anim5" anim={"5"}>
              
              <Title className="title-box title-box--left ff--3 fs--22" content={"<span style=\"color: rgb(245, 166, 35);\">❖</span>&nbsp;<span style=\"color: rgb(245, 166, 35); font-weight: bold; background-color: transparent; letter-spacing: 0.05em;\">Ekonomický úsek</span><br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"k4kl951dc6"} style={{"paddingTop":0,"paddingBottom":4.78125,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--hvr2">
              
              <Title className="title-box title-box--left ff--3 fs--20" content={"<div><span style=\"color: rgb(155, 155, 155);\">&nbsp; &nbsp; &nbsp; Janka Burianová&nbsp;</span></div><div><span style=\"color: rgb(240, 240, 242);\">&nbsp; &nbsp; &nbsp; ekonómka, účtovníčka</span><br></div>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper style={{"paddingTop":12}}>
              
              <Title className="title-box ff--3 fs--20" style={{"paddingTop":6}} content={"<a href=\"mailto:ekonomka@tribecskemuzeum.sk\" target=\"_blank\" style=\"color: rgb(155, 155, 155);\">ekonomka@tribecskemuzeum.sk</a>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"jf6wb5pr6wo"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <SeparateLineWrap className="--invert" style={{"paddingTop":0,"paddingBottom":0}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"k4kl951dc6"} style={{"paddingTop":0,"paddingBottom":4.78125,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--hvr2">
              
              <Title className="title-box title-box--left ff--3 fs--20" content={"<div><span style=\"color: rgb(155, 155, 155);\">&nbsp; &nbsp; &nbsp; Marta Gabajová&nbsp;</span></div><div><span style=\"color: rgb(240, 240, 242);\">&nbsp; &nbsp; &nbsp; personalistka, mzdárka</span><br></div>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper style={{"paddingTop":14}}>
              
              <Title className="title-box ff--3 fs--20" style={{"marginTop":2.1875}} content={"<a href=\"mailto:personalistka@tribecskemuzeum.sk\" target=\"_blank\" style=\"color: rgb(155, 155, 155);\">personalistka@tribecskemuzeum.sk</a>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"k4kl951dc6"} style={{"paddingTop":46,"paddingBottom":4.78125,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--hvr2 js-anim  --anim5" anim={"5"}>
              
              <Title className="title-box title-box--left ff--3 fs--22" content={"<span style=\"color: rgb(245, 166, 35);\">❖</span>&nbsp;<span style=\"color: rgb(245, 166, 35); font-weight: bold; background-color: transparent; letter-spacing: 0.05em;\">Prevádzkový úsek&nbsp;</span><br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"k4kl951dc6"} style={{"paddingTop":0,"paddingBottom":4.78125,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--hvr2">
              
              <Title className="title-box title-box--left ff--3 fs--20" content={"<div><span style=\"color: rgb(155, 155, 155);\">&nbsp; &nbsp; &nbsp; Lenka Halová&nbsp;</span></div><div><span style=\"color: rgb(240, 240, 242);\">&nbsp; &nbsp; &nbsp; upratovačka</span><br></div>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"jf6wb5pr6wo"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <SeparateLineWrap className="--invert" style={{"paddingTop":0,"paddingBottom":0}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"k4kl951dc6"} style={{"paddingTop":0,"paddingBottom":90.1640625,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--hvr2">
              
              <Title className="title-box title-box--left ff--3 fs--20" content={"<div><span style=\"color: rgb(155, 155, 155);\">&nbsp; &nbsp; &nbsp; Peter Guba&nbsp;&nbsp;</span></div><div><span style=\"color: rgb(240, 240, 242);\">&nbsp; &nbsp; &nbsp; vodič, údržbár</span><br></div>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"odber_noviniek"} style={{"paddingTop":7,"paddingBottom":0,"backgroundColor":"rgba(245,166,35,1)"}}>
        </SeparateLine>


        <Column className="pt--30" name={"odoberat_novinky"} style={{"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="--hvr5 js-anim  --anim5" anim={"5"}>
              
              <Title className="title-box title-box--left ff--3 fs--30" content={"<span style=\"color: rgb(155, 155, 155); font-weight: bold;\">Prihláste sa na odber našich noviniek</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pt--10" name={"y3plkeadv5"} style={{"paddingBottom":2.5,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <SeparateLineWrap className="--invert" style={{"paddingBottom":24.046875}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"bnspx2a0ddg"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --hvr5 --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--left ff--3 fs--22" content={"<span style=\"color: rgb(255, 255, 255);\">Buďte prvý, kto sa dozvie o najnovších výstavách, podujatiach a ďalších novinkách múzea</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pt--10" name={"y3plkeadv5"} style={{"paddingBottom":2.5,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <SeparateLineWrap className="--invert" style={{"paddingBottom":24.046875}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--30" name={"email"} style={{"paddingTop":0,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <ColumnWrap className="column__flex el--2 --full flex--center" columns={"2"} fullscreen={true}>
            
            <ColumnWrapper className="--hvr5 js-anim  --anim3" anim={"3"} style={{"marginTop":5.8359375,"paddingTop":5.5078125,"marginBottom":86.65625}}>
              
              <Subtitle className="subtitle-box subtitle-box--center ff--3 fs--30" style={{"color":"var(--color-variable-1)"}} content={"<span style=\"color: rgb(155, 155, 155); font-weight: bold;\">Spojte sa s nami</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4" anim={"4"}>
              
              <ContactForm className="--shape2 --shape3 --style3 ff--3 fs--20" action={"/contact"} fields={[{"type":"email","required":true},{"name":"Odoberať novinky","type":"submit"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"p3ida6q9m6r"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--hvr5" style={{"marginTop":0,"marginBottom":0,"paddingBottom":0}}>
              
              <Title className="title-box title-box--left ff--3 fs--20 lh--14" style={{"marginTop":0,"marginBottom":0,"paddingBottom":0}} content={"<span style=\"color: rgb(155, 155, 155);\">Súhlasím s tým, že Tribečské múzeum v Topoľčanoch bude používať moje osobné údaje v súlade s právnymi predpismi o ochrane údajov. Rozumiem, že múzeum použije moje osobné údaje aj na poskytovanie požadovaných služieb a na analýzu činností a preferencií návštevníkov.\n<br></span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"7fb9bonli9f"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--hvr5">
              
              <Title className="title-box title-box--left ff--3 fs--20" content={"<span style=\"color: rgb(155, 155, 155);\">Rozumiem, že sa môžem kedykoľvek odhlásiť kliknutím na odkaz na zrušenie odberu v dolnej časti e-mailu.</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"7fb9bonli9f"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --hvr2 --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--hvr5 js-anim  --anim5" anim={"5"}>
              
              <Title className="title-box title-box--left ff--3 fs--20" content={"<a href=\"/ochrana-osobnych-udajov\" style=\"color: rgb(245, 166, 35);\">Ochrana osobných údajov&nbsp;</a><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"y3plkeadv5"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <SeparateLineWrap className="--invert" style={{"paddingBottom":12.9765625}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"jjakgrcqb9d"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":1035}}>
              
              <Subtitle className="subtitle-box ff--3 fs--16" content={"<span style=\"color: rgb(155, 155, 155);\">Podnety podľa zák. č. 54/2019 Z. z. o ochrane oznamovateľov možno podávať na adrese: </span><span style=\"color: rgb(255, 255, 255);\"><a href=\"mailto:sekretariat@tribecskemuzeum.sk\" target=\"_blank\">sekretariat@tribecskemuzeum.sk</a></span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"y3plkeadv5"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <SeparateLineWrap className="--invert" style={{"paddingBottom":12.9765625}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"m1s0xg0ntq"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"} fullscreen={false}>
            
            <ColumnWrapper className="--hvr2 js-anim  --anim5" anim={"5"}>
              
              <Image src={"https://cdn.swbpg.com/t/7746/adaac89956384953972eaf4ebf0bcc41_s=860x_.png"} svg={false} href={"https://www.facebook.com/Tribe%C4%8Dsk%C3%A9-m%C3%BAzeum-Topo%C4%BE%C4%8Dany-918391598311409/"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":40}} srcSet={"https://cdn.swbpg.com/t/7746/adaac89956384953972eaf4ebf0bcc41_s=350x_.png 350w, https://cdn.swbpg.com/t/7746/adaac89956384953972eaf4ebf0bcc41_s=660x_.png 660w, https://cdn.swbpg.com/t/7746/adaac89956384953972eaf4ebf0bcc41_s=860x_.png 860w, https://cdn.swbpg.com/t/7746/adaac89956384953972eaf4ebf0bcc41_s=1400x_.png 1400w"} RootClassName={"column__icon"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr2 js-anim  --anim3" anim={"3"}>
              
              <Image src={"https://cdn.swbpg.com/t/7746/2138ded215e04bd5bc46083a3b6922d0_s=860x_.png"} svg={false} href={"https://www.instagram.com/tribecske_muzeum/"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":40}} srcSet={"https://cdn.swbpg.com/t/7746/2138ded215e04bd5bc46083a3b6922d0_s=350x_.png 350w, https://cdn.swbpg.com/t/7746/2138ded215e04bd5bc46083a3b6922d0_s=660x_.png 660w, https://cdn.swbpg.com/t/7746/2138ded215e04bd5bc46083a3b6922d0_s=860x_.png 860w, https://cdn.swbpg.com/t/7746/2138ded215e04bd5bc46083a3b6922d0_s=1400x_.png 1400w"} RootClassName={"column__icon"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr2 js-anim  --anim2" anim={"2"}>
              
              <Image src={"https://cdn.swbpg.com/t/7746/56da973fc65d485abd6f2be6e25d2f72_s=860x_.png"} svg={false} href={"https://www.youtube.com/channel/UC_9B5K30zlouITbYYku8jjg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":40}} srcSet={"https://cdn.swbpg.com/t/7746/56da973fc65d485abd6f2be6e25d2f72_s=350x_.png 350w, https://cdn.swbpg.com/t/7746/56da973fc65d485abd6f2be6e25d2f72_s=660x_.png 660w, https://cdn.swbpg.com/t/7746/56da973fc65d485abd6f2be6e25d2f72_s=860x_.png 860w, https://cdn.swbpg.com/t/7746/56da973fc65d485abd6f2be6e25d2f72_s=1400x_.png 1400w"} RootClassName={"column__icon"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr2 js-anim  --anim4" anim={"4"}>
              
              <Image src={"https://cdn.swbpg.com/t/7746/1af4ccd94ed4455f8b0b071f7121468b_s=860x_.png"} url={"https://tribecskem.podbean.com/"} href={"https://tribecskem.podbean.com/"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":40}} srcSet={"https://cdn.swbpg.com/t/7746/1af4ccd94ed4455f8b0b071f7121468b_s=350x_.png 350w, https://cdn.swbpg.com/t/7746/1af4ccd94ed4455f8b0b071f7121468b_s=660x_.png 660w, https://cdn.swbpg.com/t/7746/1af4ccd94ed4455f8b0b071f7121468b_s=860x_.png 860w, https://cdn.swbpg.com/t/7746/1af4ccd94ed4455f8b0b071f7121468b_s=1400x_.png 1400w"} RootClassName={"column__icon"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"y3plkeadv5"} style={{"paddingTop":0,"paddingBottom":2.5,"backgroundColor":"rgba(42, 41, 41, 1)"}}>
          
          <SeparateLineWrap className="--invert" style={{"paddingBottom":24.046875}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"4gjcncmcxgb"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--hvr2 js-anim  --anim2" anim={"2"} style={{"paddingTop":12.570709228515623,"paddingBottom":12.93280029296875}}>
              
              <Button className="btn-box btn-box--style2 ff--3 fs--13 w--900 ls--001" href={"#uvod"} content={"Späť hore"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"rxkv7njo28g"} style={{"paddingTop":0,"marginBottom":0,"paddingBottom":13.25,"backgroundColor":"rgba(42, 41, 41, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1 --full" columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="--left" style={{"paddingTop":0,"paddingBottom":0}}>
              
              <Image className="--left" src={"https://cdn.swbpg.com/t/7746/7392d640b81742039a53bd8760f6eedb_s=860x_.png"} sizes="100vw" style={{"maxWidth":159}} srcSet={"https://cdn.swbpg.com/t/7746/7392d640b81742039a53bd8760f6eedb_s=350x_.png 350w, https://cdn.swbpg.com/t/7746/7392d640b81742039a53bd8760f6eedb_s=660x_.png 660w, https://cdn.swbpg.com/t/7746/7392d640b81742039a53bd8760f6eedb_s=860x_.png 860w, https://cdn.swbpg.com/t/7746/7392d640b81742039a53bd8760f6eedb_s=1400x_.png 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"3u406bnw8id"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42,41,41,1)"}}>
        </SeparateLine>


        <Column name={"yd51ag8z14"} style={{"paddingTop":0,"paddingBottom":7,"backgroundColor":"rgba(0,0,0,1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"backgroundColor":"rgba(0,0,0,1)"}} columns={"1"}>
            
            <ColumnWrapper style={{"paddingTop":13}}>
              
              <Text className="text-box text-box--left ff--3 fs--14" content={"<span style=\"color: rgb(155, 155, 155);\">© 2023 Tribečské múzeum v Topoľčanoch, všetky práva vyhradené</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}